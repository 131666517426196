import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import Loader from "../../utils/Loader/Loader";
import { useMediaQuery } from "@chakra-ui/react";
import DesktopBrowserRecommended from "./DesktopBrowserRecommended";

const RedirectAuthenticated = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  const [isSmallerThan800] = useMediaQuery('(max-width: 800px)')

  if(isSmallerThan800) {
    return <DesktopBrowserRecommended />
  }

  if (isLoading) {
    return <Loader />;
  }
  if (isAuthenticated) {
    window.location.href = "/dashboard";
  } else {
    return <Component {...rest} />;
  }

};

export default RedirectAuthenticated;
