import { FormControl, FormLabel } from "@chakra-ui/react";
import Select from "react-select";
import { getHostDomain } from "../../utils/utils";
import useExternalAuth from "../Tools/useExternalAuth";
import useToolUserRunStaticFunction from "../Tools/useToolUserRunStaticFunction";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";

export default function Instagram({ setValue, watch }) {
  const baseUrl = getHostDomain();
  const { tool_user_id } = watch();

  const { auths } = useExternalAuth({
    auth_provider: "instagram",
  });

  const pageOptions = auths
    ? auths?.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : [];

  const handleSelect = ({ label, value }) => {
    setValue("tool_user_id", value);
    getIgDetails(value)
  };

  async function getIgDetails(toolUserId) {
    try {
      if (!toolUserId) return;
      const response = await fetchController(
        baseUrl + `/api/v1/tool/ToolUser/${toolUserId}/run_static_function`,
        "POST",
        {
          function_name: "get_insta_details",
        }
      );
      
      setValue("ig_name", response?.name);
      setValue("ig_id", response?.id);
      setValue("ig_username ", response?.username);
      setValue("ig_user_id", response?.user_id);
    } catch (error) {
      toast.error("Some error occured");
    }
  }

  return (
    <>
      <FormControl isRequired>
        <FormLabel>{"Select IG"}</FormLabel>
        <Select options={pageOptions} onChange={handleSelect} />
      </FormControl>
    </>
  );
}
