import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPhone } from "react-icons/fa";
import { MdOutlineWebAsset } from "react-icons/md";
import Select from "react-select";
import { toast } from "react-toastify";
import { widgetTypes } from "../../utils/Data/data";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";
import FormSelect from "../Widgets/FormSelect";
import useTwilioAccounts from "../Widgets/useTwilioAccounts";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import SelectTwilioAccount from "../Widgets/SelectTwilioAccount";
import SelectPhoneNumber from "../Widgets/SelectPhoneNumber";
import useExternalAuth from "../Tools/useExternalAuth";
import SMSFields from "../Widgets/SMSFields";
import { getHostDomain } from "../../utils/utils";
import LeadConnectorFields from "./../Widgets/LeadConnectorFields";
import { GiBugleCall } from "react-icons/gi";
import { FiPhoneMissed } from "react-icons/fi";
import FBMessenger from "../Widgets/FBMessenger";
import Instagram from "../Widgets/Instagram";

const AddWidget = ({ isOpen, onClose, fetchAssistants }) => {
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const agency_id = agency.myDetails?.agency_id;
  const agency_name = agency.myDetails?.branding?.agency_name;
  const finalRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      widget_type: "",
      assistant_id: "",
      twilio_auth_id: "",
      phone_number: "",
      max_call_duration: 600,
      form: null,
      recording: false,
      form_phone_number_field: "",
      sid: "",
    },
  });
  const endpoint = "/api/v1/assistant/list?page=1&size=50";
  const [assistants, setAssistants] = useState([]);
  const [details, setDetails] = useState([]);

  const urlHit = baseUrl + endpoint;
  const hitFetch = async () => {
    try {
      const getData = await fetchController(urlHit, "GET");
      const Wendpoint = "/api/v1/user/list/UserWhatsApp?page=1&size=50";
      const WurlHit = baseUrl + Wendpoint;
      const getDataWhatsapp = await fetchController(WurlHit, "GET");
      if (
        getDataWhatsapp &&
        getDataWhatsapp.data &&
        getDataWhatsapp.data.items.length > 0
      ) {
        const latestDetails =
          getDataWhatsapp.data.items[getDataWhatsapp.data.items.length - 1]; // Get the last item in the array

        if (latestDetails) {
          setDetails(latestDetails);
        }
      }
      if (getData?.data) {
        const data = getData?.data?.items.map((item) => {
          return {
            value: item.id,
            label: item.name,
            assistant_type: item.assistant_type,
          };
        });
        if (data) {
          setAssistants(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (isOpen) {
      hitFetch();
    }
  }, [isOpen]);

  const onSubmit = async (data) => {
    try {
      const endpoint = "/api/v1/widget";
      const urlHit = baseUrl + endpoint;
      if (!data.widget_type) {
        toast.error("Please specify type of widget");
        return;
      }
      const body = {
        ...data,
        attributes:
          watch("widget_type") === "chat"
            ? {
                initial_form_id: data.form?.id || null,
              }
            : watch("widget_type") === "whatsapp"
            ? {
                whatsapp_phone_number: data.whatsapp_phone_number,
                phone_number_id: data.phone_number_id,
                phone_business_id: data.phone_business_id,
                whatsapp_access_token: data.whatsapp_access_token,
                facebook_app_secret: data.facebook_app_secret,
              }
            : watch("widget_type") === "phone"
            ? {
                sid: data.sid,
                phone_number: data.phone_number,
                twilio_auth_id: data.twilio_auth_id,
                phone_provider: "twilio",
                recording: data.recording,
                max_call_duration: data.max_call_duration,
              }
            : watch("widget_type") === "html_call"
            ? {
                sid: data.sid,
                phone_number: data.phone_number,
                twilio_auth_id: data.twilio_auth_id,
                phone_provider: "twilio",
                recording: data.recording,
                max_call_duration: data.max_call_duration,
                form_phone_number_field: data.form_phone_number_field,
                form_id: data.form?.id,
              }
            : data.widget_type === "sms"
            ? {
                sid: data.sid,
                phone_number: data.phone_number,
                tool_user_id: data.tool_user_id,
              }
            : data.widget_type === "leadconnector"
            ? {
                location_id: data.location_id,
                channels: data.channels,
                tool_user_id: data.tool_user_id,
              }
            : data.widget_type === "leadconnector_call"
            ? {
                sid: data.sid,
                phone_number: data.phone_number,
                call_status: data.call_status,
                twilio_auth_id: data.twilio_auth_id,
                tool_user_id: data.tool_user_id,
                location_id: data.location_id,
              }
            : data.widget_type === "fb_messenger"
            ? {
                fb_page_id: data?.fb_page_id,
                fb_page_name: data?.fb_page_name,
                tool_user_id: data?.tool_user_id,
              }
            : data.widget_type === "instagram"
            ? {
                tool_user_id: data?.tool_user_id,
                ig_user_id: data?.ig_user_id,
                ig_name: data?.ig_name,
                ig_username: data?.ig_username,
              }
            : {},
        user_opening_messages: [],
        action_buttons: [],
        style_params: {
          agency_name: agency_id ? agency_name : "Insighto.AI",
        },
      };
      await fetchController(urlHit, "POST", body);
      showToast("Widget Added", "success");
      reset();
      fetchAssistants();
      onClose();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const selectedAssistant = assistants.find(
    (assistant) => assistant?.value === watch("assistant_id")
  );
  const fetchWhtsappDetails = async () => {
    setValue("whatsapp_phone_number", details.whatsapp_phone_number);
    setValue("phone_number_id", details.phone_number_id);
    setValue("phone_business_id", details.phone_business_id);
    setValue("whatsapp_access_token", details.whatsapp_access_token);
    setValue("facebook_app_secret", details.facebook_app_secret);
  };
  const { accounts, phoneNumbers } = useTwilioAccounts({
    widgetType: watch("widget_type"),
    twilio_auth_id: watch("twilio_auth_id"),
  });
  const telephoneFieldsOptions = (watch("form")?.fields || [])
    .filter((field) => field.type === "tel")
    .map((field) => ({
      label: field.name,
      value: field.name,
    }));

  const { buttonColorScheme } = agency;
  const { auths } = useExternalAuth({
    auth_provider: "twilio",
  });
  const { auths: leadConnectorAuths } = useExternalAuth({
    auth_provider: "leadconnector",
  });

  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setValue("name", "");
        }}
      >
        <ModalOverlay />
        <ModalContent height="auto" width="95%" maxWidth="600px">
          <ModalHeader>Add a Widget</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" mb="4">
                <Box flex="1">
                  <Input
                    placeholder="Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Name is required
                    </p>
                  )}
                </Box>
                <Box flex="1" mr="2">
                  <Select
                    placeholder="Select Assistant"
                    {...register("assistant_id", { required: true })}
                    options={assistants && assistants}
                    value={assistants.find(
                      (assistant) => assistant.value === watch("assistant_id")
                    )}
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      setValue("assistant_id", selectedOption?.value || null, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                      setValue("widget_type", "");
                    }}
                  />
                  {errors.assistant_id && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Assistant required
                    </p>
                  )}
                </Box>
              </Stack>
              <Textarea
                placeholder="only for your reference, not used as part of the prompt"
                {...register("description", { required: true })}
                resize="vertical"
              />
              {errors.description && (
                <p style={{ color: "red", fontSize: "11px" }}>
                  Description is required
                </p>
              )}

              {selectedAssistant ? (
                <Grid
                  gridTemplateColumns={"auto 1fr"}
                  alignItems={"center"}
                  marginBlock={4}
                >
                  <Text mr="2" fontWeight="bold">
                    Widget Type:
                  </Text>
                  {watch("widget_type") !== "phone" &&
                  selectedAssistant.assistant_type !== "phone" ? (
                    <Flex justifyContent={"flex-start"} gap={3}>
                      {widgetTypes.map((type) => (
                        <Button
                          title={type.label}
                          isActive={watch("widget_type") === type.value}
                          colorScheme={
                            watch("widget_type") === type.value
                              ? "gray"
                              : undefined
                          }
                          key={type.value}
                          onClick={() => setValue("widget_type", type.value)}
                        >
                          {type.icon}
                        </Button>
                      ))}
                    </Flex>
                  ) : null}

                  {selectedAssistant &&
                  selectedAssistant.assistant_type === "phone" ? (
                    <Flex width={"100%"} justifyContent={"flex-start"} gap={3}>
                      <Tooltip
                        label="Use Call Inbound/Outbound to deploy your AI Agents to a phone number
"
                      >
                        <span>
                          <Button
                            title="Phone Inbound/Outbound"
                            colorScheme={
                              watch("widget_type") === "phone"
                                ? "gray"
                                : undefined
                            }
                            isActive={watch("widget_type") === "phone"}
                            onClick={() => setValue("widget_type", "phone")}
                          >
                            <FaPhone />
                          </Button>
                        </span>
                      </Tooltip>
                      <Tooltip label="Use Embed Call to deploy any Form to your website to let your users initiate a call using your AI Agent">
                        <span>
                          <Button
                            title="Embed Call"
                            colorScheme={
                              watch("widget_type") === "html_call"
                                ? "gray"
                                : undefined
                            }
                            isActive={watch("widget_type") === "html_call"}
                            onClick={() => setValue("widget_type", "html_call")}
                          >
                            <MdOutlineWebAsset />
                          </Button>
                        </span>
                      </Tooltip>
                      <Tooltip label="Trigget AI Phone Calls when you miss a call on GHL">
                        <span>
                          <Button
                            colorScheme={
                              watch("widget_type") === "leadconnector_call"
                                ? "gray"
                                : undefined
                            }
                            isActive={
                              watch("widget_type") === "leadconnector_call"
                            }
                            onClick={() =>
                              setValue("widget_type", "leadconnector_call")
                            }
                          >
                            <FiPhoneMissed />
                          </Button>
                        </span>
                      </Tooltip>
                    </Flex>
                  ) : null}
                  {errors.widget_type && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Widget Type is required
                    </p>
                  )}
                </Grid>
              ) : null}
              {selectedAssistant?.assistant_type === "simple" &&
              watch("widget_type") === "chat" ? (
                <FormControl>
                  <FormLabelWithInfoTooltip
                    label={"Inital Form"}
                    tip={
                      "This form will be pushed on the starting of the chat."
                    }
                  />
                  <FormSelect
                    selectedForm={watch("form")}
                    onChange={(selectedForm) => {
                      setValue("form", selectedForm?.value || "");
                    }}
                  />
                </FormControl>
              ) : null}
              {(selectedAssistant?.assistant_type === "simple" &&
                watch("widget_type") === "leadconnector") ||
              watch("widget_type") === "leadconnector_call" ? (
                <LeadConnectorFields
                  leadConnectorAuths={leadConnectorAuths}
                  watch={watch}
                  setValue={setValue}
                />
              ) : null}

              {selectedAssistant?.assistant_type === "simple" &&
              watch("widget_type") === "sms" ? (
                <Grid>
                  <SMSFields auths={auths} setValue={setValue} watch={watch} />
                </Grid>
              ) : null}
              {watch("widget_type") === "whatsapp" &&
                selectedAssistant &&
                selectedAssistant.assistant_type !== "phone" && (
                  <>
                    {details && (
                      <Button
                        size="xs"
                        fontSize="x-small"
                        mb={2}
                        onClick={fetchWhtsappDetails}
                      >
                        Fetch Default WhatsApp Details
                      </Button>
                    )}
                    <Input
                      placeholder="WhatsApp Phone Number"
                      type="number"
                      {...register("whatsapp_phone_number", { required: true })}
                      mb="4"
                    />
                    <Input
                      placeholder="Phone Number ID"
                      {...register("phone_number_id", { required: true })}
                      mb="4"
                    />
                    <Input
                      placeholder="Phone Business ID"
                      {...register("phone_business_id", { required: true })}
                      mb="4"
                    />
                    <Input
                      placeholder="WhatsApp Access Token"
                      {...register("whatsapp_access_token", { required: true })}
                      mb="4"
                    />
                    <Input
                      placeholder="Facebook App Secret"
                      {...register("facebook_app_secret", { required: true })}
                      mb="4"
                    />
                    <Flex justifyContent={"flex-start"}>
                      <Link
                        color={"blue.500"}
                        href="https://developers.facebook.com/docs/whatsapp/cloud-api/get-started"
                        isExternal
                      >
                        Get Keys <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Flex>
                  </>
                )}

              {["phone", "html_call", "leadconnector_call"].includes(
                watch("widget_type")
              ) &&
                selectedAssistant &&
                selectedAssistant.assistant_type === "phone" && (
                  <Stack spacing={3}>
                    {watch("widget_type") === "leadconnector_call" ? null : (
                      <>
                        <Checkbox
                          onChange={(e) => {
                            setValue("recording", e.currentTarget.checked);
                          }}
                          isChecked={watch("recording")}
                        >
                          Recording
                        </Checkbox>
                        <FormControl>
                          <FormLabel>Max Call Duration (seconds)</FormLabel>
                          <Input
                            placeholder="Max Call Duration (seconds)"
                            type="number"
                            {...register("max_call_duration", {
                              valueAsNumber: true,
                              required: true,
                            })}
                          />
                          <FormErrorMessage>
                            {errors.max_call_duration &&
                            errors.max_call_duration.type === "required"
                              ? "Field required"
                              : errors.max_call_duration &&
                                errors.max_call_duration.type ===
                                  "valueAsNumber"
                              ? "Value should be number"
                              : null}
                          </FormErrorMessage>
                        </FormControl>
                      </>
                    )}
                    <SelectTwilioAccount
                      accounts={accounts}
                      errors={errors}
                      watch={watch}
                      setValue={setValue}
                    />
                    <SelectPhoneNumber
                      watch={watch}
                      setValue={setValue}
                      phoneNumbers={phoneNumbers}
                    />

                    {watch("widget_type") === "html_call" && (
                      <>
                        <FormControl isRequired>
                          <FormLabelWithInfoTooltip
                            label={"Form"}
                            tip={
                              "This Form will be rendered to your users. Use this to collect info before initiating the call"
                            }
                          />
                          <FormSelect
                            selectedForm={watch("form")}
                            onChange={({ value }) => {
                              setValue("form", value);
                              setValue("form_phone_number_field", "");
                            }}
                          />
                        </FormControl>
                        {watch("form") ? (
                          <FormControl isRequired>
                            <FormLabelWithInfoTooltip
                              label={" Telephone field"}
                              tip={
                                "User will provide their phone number in this field. AI Agent will call this number as soon as they submit the form"
                              }
                            />
                            <Select
                              required
                              onChange={({ value }) => {
                                setValue("form_phone_number_field", value);
                              }}
                              options={telephoneFieldsOptions}
                              value={telephoneFieldsOptions.find(
                                (field) =>
                                  field.value ===
                                  watch("form_phone_number_field")
                              )}
                            />
                          </FormControl>
                        ) : null}
                      </>
                    )}
                    <Flex justifyContent={"flex-start"}>
                      <Link
                        color={"blue.500"}
                        href="https://www.twilio.com/en-us/blog/better-twilio-authentication-csharp-twilio-api-keys"
                        isExternal
                      >
                        Get Keys <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Flex>
                  </Stack>
                )}

              {selectedAssistant?.assistant_type === "simple" &&
                watch("widget_type") === "fb_messenger" && (
                  <FBMessenger setValue={setValue} watch={watch} />
                )}

              {selectedAssistant?.assistant_type === "simple" &&
                watch("widget_type") === "instagram" && (
                  <Instagram setValue={setValue} watch={watch} />
                )}

              <Button colorScheme={buttonColorScheme} type="submit" mt="4">
                Submit
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddWidget;
