import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { agencyBillingPlanGroupENUM, getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";

const AddCustomBillingPlan = ({
  isOpen,
  onOpen,
  onClose,
  fetchAgencyBillingPlanList,
  tableData = [],
}) => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const [billingPlanOption, setBillingPlanOption] = useState([]);
  const [stripeBillingPlanOption, setstripeBillingPlanOption] = useState([]);
  const finalRef = useRef(null);
  const agency = useContext(AgencyContext);
  const { agencyData, textColor, buttonColorScheme, cardBg } = agency;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const endpoint = `/api/v1/agency/billing/${agencyData.id}/billing_plan`;
      const urlHit = baseUrl + endpoint;
      const body = {
        plan_lookup_key: data.stripe_product_price,
        ...data,
        default_plan: tableData.length ? false : true,
        agency_id: agencyData.id,
        attributes: {},
      };

      const response = await fetchController(urlHit, "POST", body);
      if (response?.message) {
        toast.success(response?.message);
        fetchAgencyBillingPlanList();
        onClose();
        reset();
      }
    } catch (error) {
      toast.error("Oops! Something went wrong.");
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const loadAllBillingPlanOption = async () => {
    try {
      const res = await fetchController(
        `${baseUrl}/api/v1/agency/billing/${agencyData?.id}/get_all_billing_options`,
        "GET"
      );
      setBillingPlanOption(res.length ? res : []);
    } catch (error) {
      console.log({ error });
    }
  };
  const loadStripeBillingPlan = async () => {
    try {
      const res = await fetchController(
        `${baseUrl}/api/v1/agency/billing/${agencyData?.id}/get_stripe_billing_plans`,
        "GET"
      );
      const options = Object.values(res)
        .filter((obj) => obj.prices[0]?.active && !!obj.prices[0].lookup_key)
        .map((val) => {
          return {
            value: val.prices[0].lookup_key,
            label:
              val.name +
              "_" +
              val.prices[0]["unit_amount"] +
              "_" +
              val.prices[0].currency,
          };
        });
      setstripeBillingPlanOption(options);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    loadAllBillingPlanOption();
    loadStripeBillingPlan();
  }, []);
  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent height="auto" width="95%" maxWidth="900px">
          <ModalHeader color={textColor}>Custom Billing Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                maxH="900px"
                templateRows="repeat(10, 1fr)"
                templateColumns="repeat(4, 1fr)"
                gap={4}
              >
                <GridItem colSpan={2}>
                  <FormLabelWithInfoTooltip
                    label="Plan Name"
                    tip={"This will appear as your plan name to your customers"}
                  />
                  <Input
                    placeholder="Plan Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Plan Name is required
                    </p>
                  )}
                </GridItem>
                <GridItem colSpan={2}>
                  <FormLabelWithInfoTooltip
                    label={"Billing Plan group"}
                    tip={
                      "Regular plans are ones which users subscribe and charged monthly. Add-ons can be bought on top of regular plans in order to meet their extended needs."
                    }
                  />
                  <Select
                    {...register("billing_plan_group", { required: true })}
                  >
                    {Object.keys(agencyBillingPlanGroupENUM).map((key) => (
                      <option key={key} value={key}>
                        {" "}
                        {agencyBillingPlanGroupENUM[key]}
                      </option>
                    ))}
                  </Select>
                  {errors.billing_plan_group && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Billing plan group is required
                    </p>
                  )}
                </GridItem>
                <GridItem colSpan={4}>
                  <FormLabelWithInfoTooltip label="Map to Stripe Plan" />
                  <FormControl as="fieldset">
                    <Select
                      {...register("stripe_product_price", { required: true })}
                    >
                      <option value={""}>Select</option>
                      {stripeBillingPlanOption.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </Select>
                    <FormHelperText>
                      Stripe Product Price - Select the corresponding Stripe
                      Product - make sure it has pricing information set. Stripe
                      will handle all payments based on this. More info{" "}
                      <a
                        href="https://dev.to/stripe/modeling-your-saas-business-with-products-and-prices-59e0"
                        style={{ color: textColor }}
                      >
                        here
                      </a>{" "}
                      on how to create your Stripe Plans
                    </FormHelperText>
                  </FormControl>
                  {errors.stripe_product_price && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Stripe Product Price is required
                    </p>
                  )}
                </GridItem>
                <GridItem colSpan={4}>
                  <Alert status="info">
                    <AlertIcon />
                    {
                      "Make sure your every Stripe Plan has a unique lookup_value associated with it."
                    }
                  </Alert>
                </GridItem>
                <GridItem colSpan={4}>
                  <Divider />
                  <Textarea
                    {...register("description", { required: false })}
                    placeholder="Description"
                    size="sm"
                  />
                </GridItem>
                <GridItem colSpan={4}>
                  <Alert status="info">
                    <AlertIcon />
                    {`This will be used under the Plan Name on your Agency’s billing page and should be used for displaying plan price. For ex: $59 per month.It is only used for display purposes and has no impact on the actual price processed by your Stripe account.`}
                  </Alert>
                  <Divider />
                </GridItem>

                {billingPlanOption.map((val, index) => (
                  <>
                    {[
                      "premium_support",
                      "default_plan",
                      "remove_branding",
                      "advanced_intents",
                      "auto_intents",
                    ].includes(val[1]) ? (
                      <>
                        <GridItem colSpan={1}>
                          <FormLabelWithInfoTooltip
                            label={val[3]}
                            tip={val[2]}
                          />
                          <Select
                            value={
                              watch(
                                val[0] === "credits"
                                  ? `credits.${val[1].toString()}`
                                  : val[1].toString()
                              ) || "false"
                            }
                            {...register(
                              val[0] === "credits"
                                ? `credits.${val[1].toString()}`
                                : val[1].toString(),
                              { required: false }
                            )}
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </Select>
                          {errors[val[1]] && (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {val[1]} is required
                            </p>
                          )}
                        </GridItem>
                      </>
                    ) : (
                      <>
                        <GridItem colSpan={1} key={index}>
                          <FormLabelWithInfoTooltip
                            label={val[3]}
                            tip={val[2]}
                          />
                          <Input
                            type="number"
                            {...register(
                              val[0] === "credits"
                                ? `credits.${val[1].toString()}`
                                : val[1].toString(),
                              { required: true }
                            )}
                            defaultValue={0}
                          />
                          {errors[
                            val[0] === "credits"
                              ? `credits.${val[1].toString()}`
                              : val[1].toString()
                          ] && (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {val[1]} is required
                            </p>
                          )}
                        </GridItem>
                      </>
                    )}
                  </>
                ))}
              </Grid>
              <Flex justifyContent={"end"}>
                <Button
                  isLoading={isLoading}
                  width="80px"
                  colorScheme={buttonColorScheme}
                  type="submit"
                  mt="4"
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCustomBillingPlan;
