import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  List,
  ListItem,
  Box,
  Spinner,
  Center,
  Input,
} from "@chakra-ui/react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { useEffect, useState } from "react";
import { getHostDomain } from "../../utils/utils";

export function ContactFieldsList({ isOpen, onClose, getValue, setValue }) {
  const baseUrl = getHostDomain();
  const defaultContactField = [
    {
      label: "First Name",
      value: "first_name",
    },
    {
      label: "Last Name",
      value: "last_name",
    },
    { label: "WhatsApp", value: "channels.whatsapp" },
    { label: "Instagram", value: "channels.instagram" },
    { label: "Web", value: "channels.web" },
    { label: "Microsoft Teams", value: "channels.microsoft_teams" },
    { label: "Telephone", value: "channels.telephone" },
  ];
  const [contactField, setContactField] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const endpoint = `/api/v1/contact_custom_field/list?page=1&size=50`;

  const hitFetch = async () => {
    setLoading(true);
    try {
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      const custom_fields = response.data.items;
      const fieldData = custom_fields.map((item) => {
        return {
          value: `custom_fields.${item.custom_field_name}`,
          label: item.custom_field_name,
        };
      });

      setContactField([...defaultContactField, ...fieldData]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
      hitFetch();
  }, []);

  const labelChange = (field) => {
    const updatedValue = getValue.replace("/", `{{contact.${field.value}}}`);
    setValue("systemPrompt", updatedValue);
    onClose();
  };

  const handleClose = () => {
    if (getValue.includes("/") && !contactField.some((field) => getValue.includes(field.value))) {
      const updatedValue = getValue.replace("/", "");
      setValue("systemPrompt", updatedValue);
    }
    onClose();
  };

  const filteredContactFields = contactField.filter((field) =>
    field.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Fields</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxHeight="300px" overflowY="scroll">
            <Input
              placeholder="Search..."
              mb={4}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {loading ? (
              <Center>
                <Spinner size="xl" />
              </Center>
            ) : (
              <List spacing={3}>
                {filteredContactFields.map((field, index) => (
                  <ListItem key={field.value}>
                    <Box
                      cursor="pointer"
                      transition="background-color 0.2s ease-in-out"
                      _hover={{ bg: "gray.200" }}
                      p={3}
                      borderRadius="md"
                      border="1px"
                      borderColor="gray.200"
                      onClick={() => {
                        labelChange(field);
                      }}
                    >
                      {field.label}
                    </Box>
                  </ListItem>
                ))}
              </List>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
