import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FaPhone } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { TbMessageChatbot } from "react-icons/tb";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import { phonePromptTemplates } from "../../utils/Data/data";
import PreviewVoices from "../Assistants/PreviewVoices";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import { ContactFieldsList } from "./ContactList";
import AddUseTool from "./AddUseTool";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
import LLmModel from "../Assistants/SelectLLmModel";

const AddAssistant = ({
  isOpen,
  assistantToEdit,
  onClose,
  fetchAssistants,
}) => {
  const baseUrl = getHostDomain();
  const finalRef = useRef(null);
  const [myownVoiceOptions, setMyownVoiceOptions] = useState([]);
  const [editData, setEditData] = useState(null)
  const [member, setMember] = useState(false);
  const { state } = useContext(SettingContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      assistant_type: "simple",
      templatePhonePrompt: undefined,
      voice: true,
      hide_ds: false,
      has_human_agent: false,
      use_tools: false,
      voiceLanguages: [],
      llmModel: {
        value: "gpt-3.5-turbo-0125",
        label: "GPT-3.5-Turbo-0125",
      },
      systemPrompt:
        "As an AI assistant built by a team of engineers at Algoscale, please answer the user query with the context provided.",
    },
  });
  const [status, setStatus] = useState("idle");
  const [linkAssistantTools, setLinkAssistantTools] = useState([]);
  const loading = status === "loading";
  const resetForm = () =>
    reset({
      assistant_type: "simple",
      voice: true,
      llmModel: {
        value: "gpt-3.5-turbo-0125",
        label: "GPT-3.5-Turbo-0125",
      },
      systemPrompt: "",
      description: "",
      name: "",
      has_human_agent: false,
      use_tools: watch("use_tools"),
      voiceLanguages: voiceSsts
        .filter((item) => item.locale_code === "en-US")
        .map((language) => ({
          label: language.name,
          value: language.locale_code,
          id: language.id,
          language_group: language.language_group,
        })),
    });

  const onSubmitAssistantPhone = async (data) => {
    try {
      setStatus("loading");

      if (
        !data.custom_voice &&
        data.voiceLanguages.some((lang) => !lang.speaker)
      ) {
        toast.error("Please attach a speaker to the language");
        return;
      }

      await fetchController(
        baseUrl +
          (data.id ? `/api/v1/assistant/${data.id}` : "/api/v1/assistant"),
        data.id ? "PUT" : "POST",
        {
          assistant_type: data.assistant_type || "simple",
          llm_model: data.llmModel?.value || "gpt-3.5-turbo-1106",
          name: data.name,
          voice: true,
          system_prompt: data.systemPrompt,
          description: data.description || "string",
          use_tools: watch("use_tools"),
          voice_languages:
            data.voiceLanguages?.map((lang) => ({
              stt: lang.id,
              tts: lang.speaker.value,
            })) || [],
          custom_voice: data.custom_voice,
        }
      );
      toast.success(`Assistant ${data.id ? "updated" : "created"}`);
      fetchAssistants();
      !data.id && resetForm();
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
    }
  };

  const onSubmit = async (data) => {
    try {
      setStatus("loading");

      const endpoint = data.id
        ? `/api/v1/assistant/${data.id}`
        : "/api/v1/assistant";
      const urlHit = baseUrl + endpoint;

      if (data.voice) {
        if (data.voiceLanguages.some((lang) => !lang.speaker)) {
          toast.error("Please attach a speaker to the language");
          return;
        }
      }

      const body = {
        assistant_type: data.assistant_type || "simple",
        llm_model: data.llmModel?.value,
        name: data.name || "string",
        description: data.description || "string",
        use_tools: watch("use_tools"),
        attributes: data.attributes || {},
        system_prompt: data.systemPrompt || "",
        voice: data.voice,
        voice_languages:
          data.voiceLanguages?.map((lang) => ({
            stt: lang.id,
            tts: lang.speaker && lang.speaker.value,
          })) || [],
        custom_voice: data.custom_voice,
        show_images: data.showImages,
        hide_ds: data.hide_ds || false,
        has_human_agent: data.has_human_agent,
      };
      await fetchController(urlHit, data.id ? "PUT" : "POST", body);
      showToast(data.id ? "Assistant Updated" : "Assistant Added", "success");

      fetchAssistants();
      !data.id && resetForm();
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
    }
  };

  const loadOwnVoice = async () => {
    const endpoint = `/api/v1/voice/list`;
    const urlHit = baseUrl + endpoint;
    const getData = await fetchController(urlHit, "GET");

    const modifiedData = getData?.data?.items?.map((val, index) => {
      return {
        value: val.id,
        label: val.name,
      };
    });

    setMyownVoiceOptions(modifiedData);
  };
  const assistantTypes = [
    { type: "simple", children: TbMessageChatbot },
    { type: "phone", children: FaPhone },
  ];
  useEffect(() => {
    loadOwnVoice();
  }, []);

  const [voiceSsts, setVoiceSsts] = useState([]);
  const [voices, setVoices] = useState([]);
  const languages = voiceSsts.map((language) => ({
    label: language.name,
    value: language.locale_code,
    id: language.id,
    language_group: language.language_group,
  }));

  const getAssistantTool = async () => {
    try {
      const response = await fetchController(
        baseUrl +
          `/api/v1/assistant/${assistantToEdit.id}/LinkAssistantTool`,
        "GET"
      );
      setLinkAssistantTools(response?.data?.items || []);
      setValue("use_tools", response?.data?.items?.length > 0);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      setStatus("loadingAssitant");
      
      const response = await fetchController(
        baseUrl + "/api/v1/voice/list/voicestt"
      );
      const { data: voicesData } = await fetchController(
        baseUrl +
          "/api/v1/voice/list/voicetts?size=100"
      );
      setVoices(voicesData.items);
      setVoiceSsts(response.data.items);
      setMember(state?.credits?.human_agents > 0 ? true : false);
      if (assistantToEdit) {
        try {
          const { data } = await fetchController(
            baseUrl +
              `/api/v1/assistant/${assistantToEdit.id}`
          );
          setEditData(data)
          await getAssistantTool();

          setValue("assistant_type", data.assistant_type);
          setValue("custom_voice", data.custom_voice);
          setValue("name", data.name);
          setValue("hide_ds", data.hide_ds || false);
          setValue("has_human_agent", data.has_human_agent || false);

          setValue("description", data.description);
          setValue("systemPrompt", data.system_prompt);

          setValue("voice", data.voice);
          const selectedStts = data.voice_languages.map(
            (voice_lang) => voice_lang.stt
          );

          const selectedVoiceLanguages = response.data.items
            .filter((lang) => selectedStts.includes(lang.id))
            .map((language) => ({
              label: language.name,
              value: language.locale_code,
              id: language.id,
              language_group: language.language_group,
            }));

          const voiceLanguages = selectedVoiceLanguages.map(
            (selectedVoiceLang, index) => {
              const speakerId = data.voice_languages[index].tts;
              const voicePerson = voicesData.items.find(
                (voice) => speakerId === voice.id
              );
              return {
                ...selectedVoiceLang,
                speaker: voicePerson && {
                  label: `${voicePerson.name} (${voicePerson.credits}) credits`,
                  value: speakerId,
                },
              };
            }
          );

          setValue("voiceLanguages", voiceLanguages);
          setValue("showImages", data.show_images);
          setValue("id", data.id);
          setValue("assistant_type", data.assistant_type);
        } catch (error) {
          resetForm();
        } finally {
          setStatus("idle");
        }
      } else {
        resetForm();

        setValue(
          "voiceLanguages",
          response.data.items
            .filter((item) => item.locale_code === "en-US")
            .map((language) => ({
              label: language.name,
              value: language.locale_code,
              id: language.id,
              language_group: language.language_group,
            }))
        );
      }
      setStatus("idle");
    })();
  }, [assistantToEdit]);

  const loadingAssitant = status === "loadingAssitant";
  const form = useRef();

  const onSaveAndExit = async () => {
    form.current.dispatchEvent(new Event("submit", { cancelable: true }));
    const submit =
      watch("assistant_type") === "simple"
        ? handleSubmit(onSubmit)
        : handleSubmit(onSubmitAssistantPhone);
    submit();
    setTimeout(() => {
      if (!Object.keys(errors).length) onClose();
    }, 200);
  };

  const inputRef = useRef(null);

  const {
    isOpen: contactIsOpen,
    onOpen: contactOnOpen,
    onClose: contactOnClose,
  } = useDisclosure();

  const {
    isOpen: isToolOpen,
    onOpen: toolOnOpen,
    onClose: toolOnClose,
  } = useDisclosure();
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent width="100%" maxWidth="1200px">
          <form
            ref={form}
            onSubmit={
              watch("assistant_type") === "simple"
                ? handleSubmit(onSubmit)
                : handleSubmit(onSubmitAssistantPhone)
            }
          >
            <ModalHeader>
              {watch("id") ? "Update Assistant" : "Add an Assistant"}
            </ModalHeader>
            <ModalCloseButton />
            {loadingAssitant ? (
              <Flex justifyContent={"center"} alignItems={"center"}>
                <Spinner />
              </Flex>
            ) : (
              <ModalBody display="flex" gap="10px">
                {/* First line */}

                <Box width="550px">
                  <Flex
                    fontSize={"md"}
                    marginBlock={2}
                    justifyContent={"flex-start"}
                    gap={3}
                    alignItems={"center"}
                  >
                    <Text>Assistant Type : </Text>
                    {assistantTypes.map(({ children: AssistantIcon, type }) => (
                      <Button
                        title={type}
                        onClick={() => {
                          setValue("assistant_type", type);
                          setValue("has_human_agent", false);

                          setValue("templatePhonePrompt", undefined);
                          setValue("custom_voice", false);
                          setValue(
                            "systemPrompt",
                            "As an AI assistant built by a team of engineers at Algoscale, please answer the user query with the context provided."
                          );
                          setValue("llmModel", {
                            value: "gpt-3.5-turbo-0125",
                            label: "GPT-3.5-Turbo-0125",
                          });

                          const voice = voices.find(
                            (voice) => voice.language_group === "english"
                          );
                          setValue(
                            "voiceLanguages",
                            voiceSsts
                              .filter((item) => item.locale_code === "en-US")
                              .map((language) => ({
                                label: language.name,
                                value: language.locale_code,
                                id: language.id,
                                language_group: language.language_group,
                                speaker: voice && {
                                  label: `${voice.name} (${voice.credits}) credits`,
                                  value: voice.id,
                                },
                              }))
                          );

                          setValue("voice", true);
                        }}
                        colorScheme={
                          type === watch("assistant_type") ? "gray" : undefined
                        }
                        isActive={type === watch("assistant_type")}
                        key={type}
                      >
                        <AssistantIcon />
                      </Button>
                    ))}
                  </Flex>

                  <FormControl isInvalid={errors.name} mb="4">
                    <Input
                      placeholder="Name"
                      {...register("name", { required: "Name is required" })}
                    />
                    {errors.name && (
                      <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={errors.description} mb="4">
                    <Textarea
                      placeholder="Not used by the LLM. Please use system prompt field to finetune the model"
                      {...register("description", {
                        required: "Description is required",
                      })}
                      resize="vertical"
                    />
                    {errors.description && (
                      <FormErrorMessage>
                        {errors.description.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  {watch("assistant_type") === "simple" && (
                    <Box>
                      <Tooltip
                        aria-label="Hide DS"
                        label="Select if you don’t want to show link to your data source in the generated answer"
                      >
                        <Box>
                          <Checkbox
                            onChange={(e) =>
                              setValue("hide_ds", e.currentTarget.checked)
                            }
                            isChecked={watch("hide_ds")}
                            colorScheme={buttonColorScheme}
                          >
                            Hide Data Source(s)
                          </Checkbox>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        aria-label="Human Allowed"
                        label={
                          member
                            ? "Select if you want to add the real human to this agent"
                            : "Please upgrade to Platinum or above"
                        }
                      >
                        <Box>
                          <Checkbox
                            disabled={!member}
                            name="has_human_agent"
                            onChange={(e) =>
                              setValue(
                                "has_human_agent",
                                e.currentTarget.checked
                              )
                            }
                            isChecked={watch("has_human_agent")}
                            colorScheme={buttonColorScheme}
                          >
                            Allow Human Handover
                          </Checkbox>
                        </Box>
                      </Tooltip>
                    </Box>
                  )}

                  <Flex mt={2} justifyContent={"space-between"}>
                    <Tooltip
                      aria-label="Connected Tools"
                      placement="right"
                      label={
                        "You can connect Tools after you’ve created the Assistant"
                      }
                      isDisabled={assistantToEdit}
                    >
                      <Box>
                        <Button
                          size="xs"
                          type="button"
                          isDisabled={!assistantToEdit}
                          colorScheme={buttonColorScheme}
                          onClick={() => {
                            toolOnOpen();
                          }}
                        >
                          Connect Tools
                        </Button>
                      </Box>
                    </Tooltip>
                    <Box>
                      <Text as={"b"}>{"Connected Tools:"}</Text>{" "}
                      <Text as={"b"}>{linkAssistantTools?.length}</Text>
                    </Box>
                  </Flex>

                  <Box mt="4">
                    <LLmModel
                      editData={editData}
                      watch={watch}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                    />

                    <Box mt="3">
                      {watch("assistant_type") === "phone" ? null : (
                        <Checkbox
                          mt="10px"
                          {...register("voice")}
                          onChange={(e) => setValue("voice", e.target.checked)}
                          colorScheme={buttonColorScheme}
                        >
                          Enable Voice
                        </Checkbox>
                      )}
                      {watch("voice") &&
                        (watch("assistant_type") === "simple" ? (
                          <>
                            <FormLabel htmlFor="llmModel">
                              Speech Language Recognizer
                              <span style={{ fontSize: "12px" }}>
                                {"(max 4)"}
                              </span>
                            </FormLabel>
                            <Select
                              value={watch("voiceLanguages")}
                              placeholder="Select Voice Languages"
                              options={languages}
                              isOptionDisabled={() =>
                                (watch("voiceLanguages") || []).length >= 4
                              }
                              isMulti
                              {...register("voiceLanguages", {
                                required: "Voice Languages are required",
                              })}
                              onChange={(selectedOption) => {
                                setValue(
                                  "voiceLanguages",
                                  selectedOption || null,
                                  {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  }
                                );
                              }}
                            />
                            {(watch("voiceLanguages") || []).length ? (
                              <Box marginBlock={2}>
                                <Flex
                                  marginBlock={1}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <FormLabel>
                                    Select Speaker Languages
                                  </FormLabel>
                                </Flex>
                                <Grid gap={2}>
                                  {(watch("voiceLanguages") || []).map(
                                    (lang, currentLangIndex) => (
                                      <Grid
                                        p={1}
                                        borderRadius={"md"}
                                        border={"1px solid lightgray"}
                                        alignItems={"center"}
                                        justifyItems={"center"}
                                        gridTemplateColumns={"1fr 2fr"}
                                        key={lang.locale_code}
                                      >
                                        <Text fontWeight={"bold"}>
                                          {lang.label}
                                        </Text>
                                        <Box width={"100%"}>
                                          <Select
                                            isOptionDisabled={(option) =>
                                              option.voicetts_provider ===
                                              "elevenlabs"
                                            }
                                            isDisabled={watch("custom_voice")}
                                            value={lang.speaker || undefined}
                                            onChange={(selectedSpeaker) => {
                                              const currentSelectedLanguages =
                                                watch("voiceLanguages");
                                              setValue(
                                                "voiceLanguages",
                                                currentSelectedLanguages.map(
                                                  (lang, index) =>
                                                    currentLangIndex === index
                                                      ? {
                                                          ...lang,
                                                          speaker:
                                                            selectedSpeaker,
                                                        }
                                                      : lang
                                                )
                                              );
                                            }}
                                            options={voices
                                              .filter(
                                                (voiceItem) =>
                                                  voiceItem.language_group ===
                                                    "multilingual" ||
                                                  voiceItem.language_group ===
                                                    lang.language_group
                                              )
                                              .map((voicePerson) => ({
                                                label: `${voicePerson.name} (${voicePerson.credits} credits)`,
                                                value: voicePerson.id,
                                                voicetts_provider:
                                                  voicePerson.voicetts_provider,
                                              }))}
                                          />
                                        </Box>
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </Box>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <FormLabel htmlFor="voiceLanguages">
                              Speech Language Recognizer
                              <span style={{ fontSize: "12px" }}>
                                {"(max 1)"}
                              </span>
                            </FormLabel>
                            <Select
                              value={watch("voiceLanguages")}
                              placeholder="Select Voice Languages"
                              options={languages}
                              isOptionDisabled={() =>
                                (watch("voiceLanguages") || []).length >= 1
                              }
                              isMulti
                              {...register("voiceLanguages", {
                                required: "Voice Languages are required",
                              })}
                              onChange={(selectedOption) => {
                                setValue(
                                  "voiceLanguages",
                                  selectedOption || null,
                                  {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  }
                                );
                              }}
                            />
                            {(watch("voiceLanguages") || []).length ? (
                              <Box marginBlock={2}>
                                <Flex
                                  marginBlock={1}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <FormLabel>
                                    Select Speaker Languages
                                  </FormLabel>
                                  <PreviewVoices ttsList={voices} />
                                </Flex>
                                <Grid gap={2}>
                                  {(watch("voiceLanguages") || []).map(
                                    (lang, currentLangIndex) => (
                                      <Grid
                                        p={1}
                                        borderRadius={"md"}
                                        border={"1px solid lightgray"}
                                        alignItems={"center"}
                                        justifyItems={"center"}
                                        gridTemplateColumns={"1fr 2fr"}
                                        key={lang.locale_code}
                                      >
                                        <Text fontWeight={"bold"}>
                                          {lang.label}
                                        </Text>
                                        <Box width={"100%"}>
                                          <Select
                                            isDisabled={watch("custom_voice")}
                                            value={lang.speaker || undefined}
                                            onChange={(selectedSpeaker) => {
                                              const currentSelectedLanguages =
                                                watch("voiceLanguages");
                                              setValue(
                                                "voiceLanguages",
                                                currentSelectedLanguages.map(
                                                  (lang, index) =>
                                                    currentLangIndex === index
                                                      ? {
                                                          ...lang,
                                                          speaker:
                                                            selectedSpeaker,
                                                        }
                                                      : lang
                                                )
                                              );
                                            }}
                                            options={voices
                                              .filter(
                                                (voiceItem) =>
                                                  voiceItem.language_group ===
                                                    "multilingual" ||
                                                  voiceItem.language_group ===
                                                    lang.language_group
                                              )
                                              .map((voicePerson) => ({
                                                label: `${voicePerson.name} (${voicePerson.credits}) credits`,
                                                value: voicePerson.id,
                                              }))}
                                          />
                                        </Box>
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </Box>
                            ) : null}
                          </>
                        ))}
                      {errors.voiceLanguages && (
                        <Text fontSize="11px" color="red" mt="1">
                          {errors.voiceLanguages.message}
                        </Text>
                      )}
                    </Box>
                    <Box mt="3">
                      <Flex
                        direction={"row"}
                        justifyContent={"space-between"}
                        p={"8px 0px"}
                      >
                        <Checkbox
                          {...register("custom_voice")}
                          isChecked={watch("custom_voice")}
                          onChange={(e) =>
                            setValue("custom_voice", e.target.checked)
                          }
                          isDisabled={!myownVoiceOptions.length}
                          colorScheme={buttonColorScheme}
                        >
                          Use My Own Voice
                        </Checkbox>

                        <Text>
                          {!myownVoiceOptions.length ? (
                            <>
                              <Link
                                to={`/settings`}
                                style={{ textDecoration: "none" }}
                                _hover={{ color: "inherit" }}
                              >
                                Create your Own Voice
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </Text>
                      </Flex>
                    </Box>
                    {watch("assistant_type") === "simple" ? (
                      <Box mt="3">
                        <Flex
                          direction={"row"}
                          justifyContent={"space-between"}
                          p={"8px 0px"}
                        >
                          <Checkbox
                            {...register("showImages")}
                            onChange={(e) =>
                              setValue("showImages", e.target.checked)
                            }
                            colorScheme={buttonColorScheme}
                          >
                            <Tooltip
                              label="Assistant will display the relevant extracted images from the URLs or the ingested images from the data sources"
                              aria-label="A tooltip"
                            >
                              <Text>Show Images</Text>
                            </Tooltip>
                          </Checkbox>
                        </Flex>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
                <Box h={"100%"} flex="1">
                  <Box>
                    {watch("assistant_type") === "phone" ? (
                      <Box marginBlock={2}>
                        <FormControl>
                          <FormLabel>Prompt Template</FormLabel>
                          <Select
                            value={phonePromptTemplates.find(
                              (prom) =>
                                prom.value === watch("templatePhonePrompt")
                            )}
                            onChange={({ value }) => {
                              setValue("systemPrompt", value);
                              setValue("templatePhonePrompt", value);
                            }}
                            options={phonePromptTemplates}
                          />
                        </FormControl>
                      </Box>
                    ) : null}
                    <FormControl
                      height={"100%"}
                      isInvalid={errors.systemPrompt}
                    >
                      <FormLabel>Prompt</FormLabel>
                      <Textarea
                        ref={inputRef}
                        rows={watch("assistant_type") === "simple" ? 27 : 18}
                        placeholder="System Prompt"
                        {...register("systemPrompt", {
                          required: "System Prompt is required",
                        })}
                        resize="vertical"
                        onChange={(e) => {
                          const text = e.currentTarget.value;
                          setValue("systemPrompt", text);
                          if (
                            text.includes("/") &&
                            watch("assistant_type") === "phone"
                          )
                            contactOnOpen();
                        }}
                      />
                      {watch("assistant_type") === "phone" && (
                        <Text fontSize="sm" fontStyle="italic" mt={1}>
                          To personalize your prompt, press / to insert a
                          contact field
                        </Text>
                      )}
                      <FormErrorMessage>
                        {errors.systemPrompt?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <ContactFieldsList
                      getValue={getValues("systemPrompt")}
                      setValue={setValue}
                      isOpen={contactIsOpen}
                      onClose={contactOnClose}
                    />
                  </Box>
                </Box>
              </ModalBody>
            )}
            <ModalFooter gap={3}>
              <Button
                type="button"
                colorScheme={buttonColorScheme}
                isLoading={loading}
                onClick={onSaveAndExit}
              >
                Save & Exit
              </Button>
              {watch("id") ? (
                <Button
                  isLoading={loading}
                  colorScheme={buttonColorScheme}
                  type="submit"
                >
                  Save
                </Button>
              ) : null}
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {isToolOpen && (
        <AddUseTool
          isOpen={isToolOpen}
          onClose={toolOnClose}
          assistantToEdit={assistantToEdit}
          loadAssistantTool={getAssistantTool}
          linkAssistantTools={linkAssistantTools}
        />
      )}
    </>
  );
};
export default AddAssistant;
