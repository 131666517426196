import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Image,
  Stack,
  Tooltip,
  theme,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import React, { useContext, useState } from "react";
import { AiOutlineAim } from "react-icons/ai";
import { FaRobot } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { IoMdSettings } from "react-icons/io";
import { MdFormatListNumbered } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { LiaToolsSolid } from "react-icons/lia";
import { RiDiscordLine } from "react-icons/ri";

import {
  liveAgents,
  navItemsData_1,
  navItemsData_5,
} from "../../utils/Data/data";
import InstructionModal from "../Modals/InstructionModal";
import NavItem from "./NavItem";
import { BiSupport } from "react-icons/bi";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import AuthContext from "../Auth/AuthContext";

function SidebarUserDetails(props) {
  const navigate = useNavigate();

  const openSettingsModal = () => {
    navigate("/settings/profile");
  };
  const onClickDiscord = () => {
    window.open("https://discord.gg/3baqJDqGYQ", "_blank");
  };
  const agency = useContext(AgencyContext);
  const { buttonColorScheme, myDetails } = agency;
  const onClickFreshDesk = () => {
    window.open(
      agency?.myDetails?.agency_id
        ? "https://insightoai.freshdesk.com/support/solutions/folders/1070000339833"
        : "https://insightoai.freshdesk.com",
      "_blank"
    );
  };

  const isDocsAndDiscordVisible = agency.loading
    ? false
    : myDetails?.agency_id
    ? false
    : true;
  return (
    <>
      <Grid marginBlock={2} gap={2}>
        {isDocsAndDiscordVisible ? (
          <>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}
              flexWrap={"wrap"}
            >
              <Tooltip label="Discord">
                <Box w={"25px"} onClick={onClickDiscord} borderRadius={"full"}>
                  <RiDiscordLine cursor={"pointer"} size={30} />
                </Box>
              </Tooltip>
              <Tooltip label="Docs">
                <Box
                  w={"25px"}
                  onClick={onClickFreshDesk}
                  borderRadius={"full"}
                >
                  <BiSupport cursor={"pointer"} size={30} />
                </Box>
              </Tooltip>
            </Flex>
          </>
        ) : null}

        <Stack direction="row" spacing={6} width={"100%"}>
          <Button
            width={"100%"}
            onClick={openSettingsModal}
            rightIcon={<IoMdSettings />}
            colorScheme={buttonColorScheme}
            variant="solid"
            display="flex"
            justifyContent="space-between"
          >
            {props.navSize !== "small" && props.userName}
          </Button>
        </Stack>
      </Grid>
    </>
  );
}
export default function SideBar() {
  const [navSize, changeNavSize] = useState("large");
  const navigate = useNavigate();

  const { isAuthenticated, authUser, logout } = useContext(AuthContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleItemClick = (path) => {
    navigate(path);
  };
  const onClickLogout = () => {
    logout();
  };

  const userName =
    authUser?.first_name?.length > 15
      ? `${authUser?.first_name?.slice(0, 10)}...`
      : authUser?.first_name;

  function AIAgentsTab() {
    const location = useLocation();
    return (
      <Box borderRadius={"md"}>
        <Box onClick={() => handleItemClick("/ai-agents")}>
          <NavItem
            toolTip={
              "AI Agent helps you add your dataset, train your assistant, configure intents & launch widgets with ease."
            }
            navSize={navSize}
            icon={FaRobot}
            active={location.pathname.startsWith("/ai-agents")}
            title={"AI Agents"}
          />
        </Box>
        {["/ai-agents", "/forms", "/intents", "/tools"].some((path) =>
          location.pathname.startsWith(path)
        ) ? (
          <ul
            style={{
              listStyle: "none",
              paddingLeft: 10,
            }}
          >
            <li onClick={() => handleItemClick("/forms")}>
              <NavItem
                size="small"
                toolTip={"Configure the forms"}
                navSize={navSize}
                icon={MdFormatListNumbered}
                active={location.pathname.startsWith("/forms")}
                title={"Forms"}
              />
            </li>
            <li onClick={() => handleItemClick("/intents")}>
              <NavItem
                size="small"
                toolTip={"Configure your intents here"}
                navSize={navSize}
                icon={AiOutlineAim}
                active={location.pathname.startsWith("/intents")}
                title={"Intents"}
              />
            </li>
            <li onClick={() => handleItemClick("/tools")}>
              <NavItem
                size="small"
                toolTip={"Configure different tools"}
                navSize={navSize}
                icon={LiaToolsSolid}
                active={location.pathname.startsWith("/tools")}
                title={"Tools"}
              />
            </li>
          </ul>
        ) : null}
      </Box>
    );
  }
  const agency = useContext(AgencyContext);
  const { agencyData, myDetails } = agency;
  const color_preset = agency?.data?.branding?.color_preset;
  const iconColor = useColorModeValue(
    color_preset || theme.colors.gray[500],
    "white"
  );
  const agencyLogoUrl = myDetails ? myDetails?.branding?.logo_url : null;
  const logo = !agency.loading ? (
    myDetails?.agency_id ? (
      agencyLogoUrl ? (
        <Image
          width="150px"
          src={
            agencyLogoUrl.includes(window.origin)
              ? agencyLogoUrl
              : `${window.location.origin}${agencyLogoUrl}`
          }
          alt={`${myDetails?.branding?.agency_name}-logo`}
        />
      ) : null
    ) : (
      <Image width="150px" src="/logo/indightoai.png" alt="Insighto.ai" />
    )
  ) : null;
  return (
    <div className="sidebar">
      <Flex
        h={"100svh"}
        boxShadow="md"
        borderRadius={navSize === "small" ? "8px" : "8px"}
        w={navSize === "small" ? "75px" : "200px"}
        flexDir="column"
        overflowY={"auto"}
        justifyContent="space-between"
      >
        <Grid>
          <Flex p={2} justifyContent={"center"} alignItems="center" gap={3}>
            <IconButton
              background="none"
              mt="5px"
              _hover={{ background: "none" }}
              icon={<FiMenu />}
              onClick={() => {
                if (navSize === "small") changeNavSize("large");
                else changeNavSize("small");
              }}
              color={iconColor}
            />
            <div
              style={{
                display: navSize === "small" ? "none" : "block",
                color: "#3182ce",
              }}
            >
              {logo}
            </div>
          </Flex>
          <div>
            {navItemsData_1.map((item, index) => (
              <div key={index} onClick={() => handleItemClick(item.path)}>
                <NavItem
                  toolTip={item.toolTip}
                  navSize={navSize}
                  icon={item.icon}
                  active={item.path === window.location.pathname}
                  title={item.title}
                  description={item.description}
                />
              </div>
            ))}
            <hr />
            {navItemsData_5
              .filter((item) =>
                agency?.myDetails?.agency_id ? item.title !== "Agency" : true
              )
              .map((item, index) => (
                <div key={index} onClick={() => handleItemClick(item.path)}>
                  <NavItem
                    toolTip={item.toolTip}
                    navSize={navSize}
                    icon={item.icon}
                    active={window.location.pathname.includes(item.path)}
                    title={item.title}
                    description={item.description}
                  />
                </div>
              ))}
            <hr />
            <AIAgentsTab />
            <hr />
            {liveAgents.map((item, index) => (
              <div key={index} onClick={() => handleItemClick(item.path)}>
                <NavItem
                  toolTip={item.toolTip}
                  navSize={navSize}
                  icon={item.icon}
                  active={window.location.pathname.includes(item.path)}
                  title={item.title}
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </Grid>
        <InstructionModal onClose={onClose} isOpen={isOpen} />

        <SidebarUserDetails
          navSize={navSize}
          user={authUser}
          onClickLogout={onClickLogout}
          userName={userName}
        />
      </Flex>
    </div>
  );
}
