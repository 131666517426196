import { Box } from "@chakra-ui/react";
import GoogleCalendarModal from "./SystemToolsModals/GoogleCalendarForm";
import CallToolModal from "./SystemToolsModals/CallToolModal";
import TwilioConfig from "./SystemToolsModals/TwilioConfig";
import ModeMedModal from "./SystemToolsModals/ModeMedModal";
import PostgresConfigModal from "./SystemToolsModals/PostgresConfigModal";

export const systemToolsModal = {
  google_calendar_appointment: GoogleCalendarModal,
  call_tool: CallToolModal,
  twilio: TwilioConfig,
  modmed: ModeMedModal,
  postgres: PostgresConfigModal
};
export default function ToolConfigurationBodyForm(props) {
  const { tool } = props;
  const ConfigurationModal =
    systemToolsModal[tool?.attributes?.react_config_component] || null;

  return (
    <Box>
      {tool?.attributes?.react_config_component ? (
        <ConfigurationModal {...props} />
      ) : null}
    </Box>
  );
}
