import {
  Button,
  Flex,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { GrAnnounce } from "react-icons/gr";
export default function Announcements({ isOpen, onClose }) {
  const annoucements = [
    {
      value:
        "Announcing our BYOK add-ons to unlock the platform limits. Check out the Billing page for more details",
      date: moment(new Date("2024-05-22")).utc().format("LL"),
    },
    {
      value:
        "The Founding Agency Pack is available at the inaugural price until this Sunday. Get your license today by contacting our Support team!",
      date: moment(new Date("2024-07-01")).utc().format("LL"),
    },
  ];
  return (
    <Modal
      scrollBehavior="inside"
      isOpen={isOpen}
      size={"xl"}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <Flex justifyContent={"flex-start"} alignItems={"center"}>
          <ModalHeader>Annoucements</ModalHeader>
          <GrAnnounce size={25} />
        </Flex>
        <ModalCloseButton />
        <ModalBody>
          <List display={"grid"} gap={3}>
            {annoucements.map((statement, index) => (
              <ListItem
                padding={2}
                borderRadius={"md"}
                border={"1px solid lightgray"}
                key={index}
              >
                <Text>{statement.value}</Text>
                <Text color={"gray"} textAlign={"right"} fontSize={"small"}>
                  {statement.date}
                </Text>
              </ListItem>
            ))}
          </List>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
