import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { getHostDomain } from "../../utils/utils";

export default function useConfigureForm() {
  const baseUrl = getHostDomain();
  const [fields, setFields] = useState([]);
  const fieldsForm = useForm({
    defaultValues: {
      type: "text",
      description: "",
      label: "",

      options: [],
      privacy_content_url: "",
      name: "",
      tool_id: "",
      required: false,
    },
  });
  const attributesForm = useForm({
    defaultValues: {
      automate: fields.length > 0,
      followUpText:
        "Thanks for booking your slot. Please feel free to message me here in case I can help you with anything.",
      contactField: "",
      twilio_auth: "",
      phone_number: "",
      automate_type: "",
      sid: "",
      should_send_me_too: false,
      send_me_too: "",
      mandatory_submission: false,
    },
  });
  const formDetailsForm = useForm({
    defaultValues: {
      submitLabel: "Submit",
      triggerInstructions: `trigger this form when the user searches or shows interest in a property`,
      webhook_id: "",
      type: "simple",
      trigger_tools: [],
      name: "",
    },
  });
  const { formId = "" } = useParams();
  const onSetFields = (newFields) => setFields(newFields);
  const [status, setStatus] = useState("idle");
  const navigate = useNavigate();
  const fetchForm = async () => {
    try {
      setStatus("loading");
      const data = await fetchController(baseUrl + `/api/v1/form/${formId}`);
      setFields(
        data.data.fields.map((field) =>
          field.type === "select"
            ? {
                ...field,
                options: field.options.map((fieldOption) => ({
                  option: fieldOption,
                })),
              }
            : field
        )
      );
      if (data.data.name) formDetailsForm.setValue("name", data.data.name);
      if (data.data.submit_label)
        formDetailsForm.setValue("submitLabel", data.data.submit_label);
      if (data.data.form_type)
        formDetailsForm.setValue("type", data.data.form_type);
      if (data.data.webhook_id)
        formDetailsForm.setValue("webhook_id", data.data.webhook_id);
      if (data.data.trigger_instructions)
        formDetailsForm.setValue(
          "triggerInstructions",
          data.data.trigger_instructions
        );
      if (data.data.attributes?.text)
        attributesForm.setValue("followUpText", data.data.attributes?.text);
      if (data.data.attributes?.automated_follow_up)
        attributesForm.setValue(
          "automate",
          data.data.attributes?.automated_follow_up
        );
      if (data.data.attributes?.contact_field)
        attributesForm.setValue(
          "contactField",
          data.data.attributes?.contact_field
        );
      attributesForm.setValue("automate_type", data.data.attributes.channel);
      const mandatory_submission = data.data.attributes.mandatory_submission;
      attributesForm.setValue(
        "mandatory_submission",
        mandatory_submission
      );
      if (data.data.attributes.channel === "sms") {
        attributesForm.setValue("sid", data.data.attributes.sid);
        attributesForm.setValue(
          "phone_number",
          data.data.attributes.phone_number
        );
        attributesForm.setValue(
          "twilio_auth",
          data.data.attributes.twilio_auth
        );
        if (data.data.attributes?.send_me_too) {
          attributesForm.setValue("should_send_me_too", true);
          attributesForm.setValue(
            "send_me_too",
            data.data.attributes.send_me_too
          );
        }
      }
      setStatus("success");
    } catch (error) {
      toast.error("Form must have been deleted");
      navigate("/configure-forms");
    } finally {
      setStatus("success");
    }
  };
  useEffect(() => {
    (async () => {
      if (!formId) {
        setFields([]);
        return;
      }
      fetchForm();
    })();
  }, [formId]);
  return {
    fieldsForm,
    attributesForm,
    fields,
    onSetFields,
    status,
    fetchForm,
    formDetailsForm,
  };
}
