import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  ModalBody,
  ModalFooter,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import FunctionCheckboxField from "./FunctionCheckboxField";
import useToolUserFunctions from "./useToolUserFunctions";
import { systemToolsModal } from "./ToolConfigurationBodyForm";
import { useNavigate } from "react-router-dom";
import { getHostDomain } from "../../utils/utils";

export default function ToolFunctionsBody({
  onClose,
  toolUserForm,
  tool,
  stepHook,
  externalAuthHook,
  toolUserStatus,
}) {
  const baseUrl = getHostDomain();
  const { watch, handleSubmit, formState } = toolUserForm;
  const { refreshAuths } = externalAuthHook;
  const { tool_function_ids = {} } = watch();
  const { toolFunctions, status } = useToolUserFunctions({ tool });
  const toolFunctionOptions = toolFunctions.map((toolFunction) => ({
    description:
      toolFunction.id in tool_function_ids
        ? tool_function_ids[toolFunction.id].description
        : toolFunction?.details?.openai_spec?.function?.description,
    id: toolFunction.id,
    name: toolFunction.name,
    isToolFunctionIdPartOfToolUser: toolFunction.id in tool_function_ids,
  }));
  const navigate = useNavigate();
  const onSubmitToolFunctionForm = async (data) => {
    try {
      const url = `/api/v1/tool/${data.tool_user_id}/LinkToolUser`;
      await fetchController(baseUrl + url, "PUT", {
        attributes: {
          tool_function_ids: data.tool_function_ids,
        },
      });
      refreshAuths();
      if (systemToolsModal[tool?.attributes?.react_config_component]) {
        stepHook.goToNext();
        return;
      }
      toast.success("Tool setup done");
      navigate("/tools");
      onClose();
    } catch (error) {
      toast.error("Some error occured! Try again later.");
    }
  };
  const toolUserLoading = toolUserStatus === "loading";
  const toolFunctionsLoading = status === "loading";
  const loading = toolUserLoading || toolFunctionsLoading;
  return (
    <form onSubmit={handleSubmit(onSubmitToolFunctionForm)}>
      <ModalBody>
        {loading ? (
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Spinner />
          </Flex>
        ) : (
          <>
            <Text>Note: Pick only the required ones for better accuracy</Text>
            <FormControl>
              <FormLabel>
                Select functions accessible to your Assistant
              </FormLabel>
              <Stack h={450} overflowY={"auto"} spacing={2}>
                {toolFunctionOptions.map((toolFunctionOption) => (
                  <FunctionCheckboxField
                    key={toolFunctionOption.id}
                    toolFunctionOption={toolFunctionOption}
                    toolUserForm={toolUserForm}
                  />
                ))}
              </Stack>
            </FormControl>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          isLoading={formState.isSubmitting}
          colorScheme="blue"
          mr={3}
          type="submit"
        >
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </form>
  );
}
