import {
  Box,
  Button,
  Center,
  Table as ChakraTable,
  Flex,
  Input,
  Spinner,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  theme,
  useClipboard,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FaWpforms } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa6";
import { AiOutlineEdit } from "react-icons/ai";
import React, { useContext, useState } from "react";
import { FaCode, FaDatabase, FaFileWaveform } from "react-icons/fa6";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { IoMdCloudUpload } from "react-icons/io";
import {
  MdDelete,
  MdOutlineDashboardCustomize,
  MdWebhook,
} from "react-icons/md";
import { RiRobot2Line } from "react-icons/ri";
import { TbTargetArrow } from "react-icons/tb";
import { TfiFiles } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import AssignAssistant from "../Modals/AssignAssistant";
import AssignIntent from "../Modals/AssignIntent";
import DataSourcesList from "../Modals/AssistantLinked";
import Code from "../Modals/Code";
import ConfirmationModal from "../Modals/Confirmation";
import DataSourcesModalLIST from "../Modals/SourceFileList";
import DataSourcesModal from "../Modals/SourcesModal";
import WidgetDataSources from "../Modals/WidgetAssistantLinkedDataSources";
import FormList from "../Modals/formList";
import AddContact from "../Modals/AddContact";
import AddAssistant from "../Modals/AddAssistant";
import WebhookModal from "../Settings/Webhooks/WebhookModal";
import AssignWebhookModal from "../Settings/Webhooks/AssignWebhook";
import { LuFileClock } from "react-icons/lu";
import WebhookLogsModal from "../Settings/Webhooks/WebhooksLogsModal";
import { LiaToolsSolid } from "react-icons/lia";
import AssignToolModal from "../Tools/AssignToolModal";
import AddEditContactCustomField from "../Modals/AddEditContactCustomField";
import TriggerToolsModal from "../Forms/TriggerToolsModal";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
const Table = ({ columns, data, table, fetch, selectedForm }) => {
  const baseUrl = getHostDomain();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: openList,
    onOpen: onOpenList,
    onClose: onCloseList,
  } = useDisclosure();
  const {
    isOpen: isWebhookLogsOpen,
    onOpen: openWebhookLogs,
    onClose: closeWebhookLogs,
  } = useDisclosure();
  const {
    isOpen: openSourceList,
    onOpen: onOpenSourceList,
    onClose: onCloseSourceList,
  } = useDisclosure();
  const {
    isOpen: OpenAssistantList,
    onOpen: onOpenAssistantList,
    onClose: onCloseAssistantLIst,
  } = useDisclosure();
  const {
    isOpen: isContactFormOpen,
    onOpen: openContactForm,
    onClose: closeContactsForm,
  } = useDisclosure();
  const {
    isOpen: isWebhookAssignOpen,
    onOpen: openWebhookAssign,
    onClose: closeWebhookAssign,
  } = useDisclosure();
  const {
    isOpen: isOpenCode,
    onOpen: onOpenCode,
    onClose: onCloseCode,
  } = useDisclosure();
  const {
    isOpen: isAssistantEditOpen,
    onOpen: openAssistantEdit,
    onClose: closeAssistantEdit,
  } = useDisclosure();
  const {
    isOpen: OpenWidgetSources,
    onOpen: onOpenWidgetSources,
    onClose: onCloseWidgetSources,
  } = useDisclosure();
  const {
    isOpen: isOpenIntent,
    onOpen: onOpenIntent,
    onClose: onCloseIntent,
  } = useDisclosure();
  const {
    isOpen: isContactCustomFieldOpen,
    onOpen: openContactCustomField,
    onClose: closeContactCustomField,
  } = useDisclosure();

  const [selectedItem, setSelectedItem] = useState(null);

  const handleUploadClick = (item) => {
    setSelectedItem(item);
    onOpen();
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    { columns, data },
    useFilters,
    useGlobalFilter,
    useSortBy // Keep using useSortBy
  );

  const [loadingRow, setLoadingRow] = useState(null);
  const navigate = useNavigate();
  const { globalFilter } = state;
  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();
  const {
    isOpen: isToolUserFormModalOpen,
    onOpen: openUserFormToolModal,
    onClose: closeUserFormToolModal,
  } = useDisclosure();
  const {
    isOpen: isAssignToolModalOpen,
    onOpen: openAssignToolModal,
    onClose: closeAssignToolModal,
  } = useDisclosure();
  const {
    isOpen: isFormOpen,
    onOpen: onOpenForm,
    onClose: onFormClose,
  } = useDisclosure();
  const handleListModal = (item) => {
    setSelectedItem(item);
    onOpenList();
  };
  const handleSourceListModal = (item) => {
    setSelectedItem(item);
    onOpenSourceList();
  };
  const handleAssistantModal = async (item) => {
    setSelectedItem(item);
    onOpenAssistantList();
  };
  const handleFormList = async (item) => {
    setSelectedItem(item);
    onOpenForm();
  };
  const handleWidgetDataSourcesModal = async (item) => {
    setSelectedItem(item);
    onOpenWidgetSources();
  };
  const [itemToDelete, setItemToDelete] = useState(null);
  const {
    isOpen: isWebhookModalOpen,
    onOpen: openWebhookModal,
    onClose: closeWebhookModal,
  } = useDisclosure();
  const onConfirmDelete = async () => {
    if (!itemToDelete) return;
    if (table === "data-sources") {
      await handledeleteClick(itemToDelete);
    } else if (table === "assistant") {
      await handleDeleteAssistant(itemToDelete);
    } else if (table === "widget") {
      await handleDeleteWidget(itemToDelete);
    } else if (table === "sourcesFileList") {
      await handleDeleteSourceFile(itemToDelete);
    } else if (table === "intent") {
      await handleDeleteIntent(itemToDelete);
    } else if (table === "forms") {
      await handleDeleteForm(itemToDelete);
    } else if (table === "apikey") {
      await handleDeleteApikey(itemToDelete);
    } else if (table === "capturedForm") {
      await handleDeleteCapturedForm(itemToDelete);
    } else if (table === "contact") {
      handleDeleteContact(itemToDelete);
    } else if (table === "webhooks") {
      handleDeleteWebhook(itemToDelete);
    } else if (table === "contactCustomField") {
      handleDeleteContactCustomField(itemToDelete);
    }
    onCloseConfirmationModal();
  };
  const handleDeleteWebhook = async (webhook) => {
    await fetchController(
      baseUrl + `/api/v1/outbound_webhook/${webhook.id}`,
      "DELETE"
    );
    toast.success("Webhook deleted");
    fetch();
  };
  const handleDeleteForm = async (form) => {
    try {
      await fetchController(baseUrl + `/api/v1/form/${form.id}`, "DELETE");
      navigate("/forms");
      fetch();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const handledeleteClick = async (item) => {
    try {
      setLoadingRow(item.id);
      const endpoint = `/api/v1/datasource/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      setLoadingRow(null);
      toast.error(error);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };

  const handleDeleteAssistant = async (item) => {
    try {
      setLoadingRow(item.id);
      const endpoint = `/api/v1/assistant/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      toast.success(response.detail, "success");
    } catch (error) {
      setLoadingRow(null);
      toast.error(error);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };

  const handleDeleteWidget = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/widget/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
      fetch();
    } catch (error) {
      setLoadingRow(null);
      toast.error(error);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };

  const handleDeleteSourceFile = async (sourceFile) => {
    try {
      setLoadingRow(sourceFile.id);
      const endpoint = `/api/v1/datasource/datasourcefile/${sourceFile.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      toast.success(response.detail);
      fetch();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoadingRow(null);
    }
  };
  const handleDeleteIntent = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/intent/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };
  const handleIntentModal = async (item) => {
    setSelectedItem(item);
    onOpenIntent();
  };
  const handleDeleteApikey = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/apikey/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };
  const handleDeleteCapturedForm = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/capturedform/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
    } finally {
      if (selectedForm) {
        const data = await fetch(selectedForm.id);
        if (data) {
          setLoadingRow(null);
        }
      }
    }
  };
  const handleDeleteContact = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/contact/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };
  const handleOpenFormToEdit = (item) => {
    navigate(`/forms/${item.id}`);
  };

  const handleCopyFormId = async (item) => {
    await navigator.clipboard.writeText(item.id);
    toast.success("Copied!");
  };

  const handleDeleteContactCustomField = async (item) => {
    try {
      setLoadingRow(item.id); // Set loading state for the clicked row
      const endpoint = `/api/v1/contact_custom_field/${item.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      showToast(response.detail, "success");
    } catch (error) {
      toast.error(error);
      setLoadingRow(null);
    } finally {
      const data = await fetch();
      if (data) {
        setLoadingRow(null);
      }
    }
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg: tableBg } = agency;
  const { onCopy, setValue, hasCopied } = useClipboard("");

  return (
    <Box p={4} shadow="lg" borderRadius="md" bg={tableBg} overflowY="auto">
      <>
        <Input
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          mb={4}
        />
        <ChakraTable {...getTableProps()} width="100%">
          <Thead>
            <Tr>
              {headerGroups.map((headerGroup, headerGroupIndex) => (
                <React.Fragment key={headerGroupIndex}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      textAlign="left"
                      p={2}
                      borderBottom="1px"
                      borderColor="gray.300"
                      // bg="gray.100"
                      bg={tableBg}
                      key={column.id}
                    >
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        {column.render("Header").replace(/_/g, " ")}
                        {column.canSort && (
                          <Box ml={2}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FiArrowDown />
                              ) : (
                                <FiArrowUp />
                              )
                            ) : (
                              <FiArrowUp opacity={0.4} />
                            )}
                          </Box>
                        )}
                      </Box>
                    </Th>
                  ))}
                  {table === "data-sources" && (
                    <Th
                      p={2}
                      width={180}
                      borderBottom="1px"
                      borderColor="gray.300"
                      // bg="gray.100"
                      bg={tableBg}
                    >
                      <Center>Operations</Center>
                    </Th>
                  )}
                  {table === "assistant" && (
                    <>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        OPERATIONS
                      </Th>
                    </>
                  )}
                  {table === "widget" && (
                    <>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        Operations
                      </Th>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        Demo
                      </Th>
                    </>
                  )}
                  {table === "sourcesFileList" && (
                    <>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        Operations
                      </Th>
                    </>
                  )}
                  {table === "intent" && (
                    <>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        OPERATIONS
                      </Th>
                    </>
                  )}
                  {table === "forms" && (
                    <>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        OPERATIONS
                      </Th>
                    </>
                  )}
                  {table === "apikey" && (
                    <>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        OPERATIONS
                      </Th>
                    </>
                  )}
                  {table === "capturedForm" && (
                    <>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        OPERATIONS
                      </Th>
                    </>
                  )}
                  {table === "contact" && (
                    <>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        OPERATIONS
                      </Th>
                    </>
                  )}
                  {table === "webhooks" && (
                    <>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        OPERATIONS
                      </Th>
                    </>
                  )}
                  {table === "contactCustomField" && (
                    <>
                      <Th
                        p={2}
                        borderBottom="1px"
                        borderColor="gray.300"
                        // bg="gray.100"
                        bg={tableBg}
                      >
                        OPERATIONS
                      </Th>
                    </>
                  )}
                </React.Fragment>
              ))}
            </Tr>
          </Thead>
          <Tbody fontSize={"large"} {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  borderBottom="1px"
                  borderColor="gray.200"
                  key={row.original.id}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()} p={2} key={cell.column.id}>
                      {cell.render("Cell")}
                    </Td>
                  ))}
                  {table === "data-sources" && (
                    <Td cursor="pointer" p={2}>
                      <Flex marginLeft="12px" gap={6}>
                        <Tooltip label="Assistants" aria-label="View tooltip">
                          <Center>
                            <RiRobot2Line
                              onClick={() =>
                                handleSourceListModal(row.original)
                              }
                              color="blue"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip label="Files" aria-label="List tooltip">
                          <Center>
                            <TfiFiles
                              onClick={() => handleListModal(row.original)}
                              color="blue"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip label="Upload" aria-label="Upload tooltip">
                          <Center>
                            <IoMdCloudUpload
                              onClick={() => handleUploadClick(row.original)}
                              color="blue"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip
                          label={
                            row.original.assistants_count > 0
                              ? "Used by assistant"
                              : "Delete"
                          }
                          aria-label="Delete tooltip"
                        >
                          <Center>
                            {loadingRow === row.original.id ? (
                              <Spinner size="sm" />
                            ) : (
                              <Box width={"fit-content"}>
                                <MdDelete
                                  onClick={() => {
                                    if (!row.original.assistants_count) {
                                      setItemToDelete(row.original);
                                      onOpenConfirmationModal();
                                    }
                                  }}
                                  color={
                                    row.original.assistants_count > 0
                                      ? "lightgray"
                                      : "red"
                                  }
                                />
                              </Box>
                            )}
                          </Center>
                        </Tooltip>
                      </Flex>
                    </Td>
                  )}
                  {table === "assistant" && (
                    <Td cursor="pointer" p={2}>
                      <Flex gap="2" marginLeft="14px">
                        {row.original.assistant_type !== "phone" ? (
                          <Tooltip
                            label="View Data Sources"
                            aria-label="View Data Sources"
                          >
                            <Center>
                              <FaDatabase
                                onClick={() => {
                                  handleAssistantModal(row.original);
                                }}
                                color="blue"
                              />
                            </Center>
                          </Tooltip>
                        ) : null}
                        <Tooltip label="View Forms" aria-label="View Forms">
                          <Center>
                            <FaFileWaveform
                              onClick={() => {
                                handleFormList(row.original);
                              }}
                              color="blue"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip
                          label="Assign Webhook"
                          aria-label="Assign Webhook"
                        >
                          <Center>
                            <MdWebhook
                              onClick={() => {
                                setSelectedItem(row.original);
                                openWebhookAssign();
                              }}
                            />
                          </Center>
                        </Tooltip>

                        {row.original.assistant_type !== "phone" ? (
                          <Tooltip
                            label="Assign Intent"
                            aria-label="Assign Intent"
                          >
                            <Center>
                              <TbTargetArrow
                                onClick={() => {
                                  handleIntentModal(row.original);
                                }}
                              />
                            </Center>
                          </Tooltip>
                        ) : null}
                        <Tooltip label="Edit" aria-label="Edit Assistant">
                          <Center>
                            <AiOutlineEdit
                              onClick={() => {
                                setSelectedItem(row.original);
                                openAssistantEdit();
                              }}
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip
                          label={"Delete Assistant"}
                          aria-label="Delete tooltip"
                        >
                          <Center>
                            {loadingRow === row.original.id ? (
                              <Spinner size="sm" />
                            ) : (
                              <MdDelete
                                onClick={() => {
                                  setItemToDelete(row.original);
                                  onOpenConfirmationModal();
                                }}
                                color={"red"}
                              />
                            )}
                          </Center>
                        </Tooltip>
                      </Flex>
                    </Td>
                  )}
                  {table === "widget" && (
                    <>
                      <Td cursor="pointer" p={2}>
                        <Flex gap="3" ml={2}>
                          <Tooltip
                            label={"Get Code & QR"}
                            aria-label="Get Code Tooltip"
                          >
                            <Center>
                              <FaCode
                                onClick={() => {
                                  setSelectedItem(row.original);
                                  onOpenCode();
                                }}
                                color="blue"
                              />
                            </Center>
                          </Tooltip>
                          {["phone", "whatsapp", "web_call"].includes(
                            row.original.widget_type
                          ) ? null : (
                            <Tooltip
                              label="Customize Widget"
                              aria-label="Customize Widget"
                            >
                              <Center>
                                <MdOutlineDashboardCustomize
                                  color="blue"
                                  onClick={() => {
                                    const type =
                                      row.original?.widget_type === "html_call"
                                        ? row.original?.widget_type
                                        : "chat";
                                    navigate(
                                      `/widgets/${row.original.id}/${type}/customize`
                                    );
                                  }}
                                />
                              </Center>
                            </Tooltip>
                          )}
                          <Tooltip
                            label={hasCopied ? "Copied!" : "Copy widget ID"}
                            aria-label="Copy widget ID"
                          >
                            <Center>
                              <TfiFiles
                                color="blue"
                                onClick={() => {
                                  setValue(row.original.id);
                                  onCopy();
                                }}
                              />
                            </Center>
                          </Tooltip>
                          <Tooltip
                            label="Delete Widget"
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <MdDelete
                                  onClick={() => {
                                    setItemToDelete(row.original);
                                    onOpenConfirmationModal();
                                  }}
                                  color="red"
                                />
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                      <Td pl="4px">
                        <Tooltip label="Demo" aria-label="Get Demo">
                          <Button
                            isDisabled={["phone", "whatsapp"].includes(
                              row.original.widget_type
                            )}
                            onClick={() => {
                              window.open(
                                row.original.widget_type === "web_call"
                                  ? `https://cdn.insighto.ai/web-call-iframe.html?widgetId=${row.original.id}`
                                  : row.original.widget_type === "html_call"
                                  ? `https://cdn.insighto.ai/call-iframe.html?widgetId=${row.original.id}`
                                  : `https://cdn.insighto.ai/widget.html?widgetId=${row.original.id}`
                              );
                            }}
                            colorScheme={buttonColorScheme}
                            width="50px"
                            height="26px"
                            fontSize="13px"
                          >
                            Visit
                          </Button>
                        </Tooltip>
                      </Td>
                    </>
                  )}
                  {table === "intent" && (
                    <>
                      <Td cursor="pointer" p={2}>
                        <Flex gap="2" marginLeft="20px">
                          <Tooltip
                            label="Delete intent"
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  {row.original.userType === "user" ? (
                                    <>
                                      <MdDelete
                                        onClick={() => {
                                          setItemToDelete(row.original);
                                          onOpenConfirmationModal();
                                        }}
                                        color="red"
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "sourcesFileList" && (
                    <Td cursor="pointer">
                      {loadingRow === row.original.id ? (
                        <Spinner size="sm" />
                      ) : (
                        <Tooltip label="Delete" aria-label="Delete tooltip">
                          <Box width={"fit-content"}>
                            <MdDelete
                              onClick={() => {
                                setItemToDelete(row.original);
                                onOpenConfirmationModal();
                              }}
                              color="red"
                            />
                          </Box>
                        </Tooltip>
                      )}
                    </Td>
                  )}
                  {table === "forms" && (
                    <Td cursor="pointer" p={2}>
                      <Flex gap="2" marginLeft="14px">
                        <Tooltip
                          label="Copy ID"
                          aria-label="Copy ID"
                          closeOnClick={false}
                        >
                          <Center>
                            <FaRegCopy
                              onClick={() => {
                                handleCopyFormId(row.original);
                              }}
                              color="black"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip
                          label="Assign tool"
                          aria-label="Copy ID"
                          closeOnClick={false}
                        >
                          <Center>
                            <LiaToolsSolid
                              onClick={() => {
                                setSelectedItem(row.original);
                                openUserFormToolModal();
                              }}
                              color="black"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip
                          label="Edit form fields"
                          aria-label="Edit form fields"
                        >
                          <Center>
                            <FaWpforms
                              onClick={() => {
                                handleOpenFormToEdit(row.original);
                              }}
                              color="blue"
                            />
                          </Center>
                        </Tooltip>
                        <Tooltip
                          label="Delete form field"
                          aria-label="Delete form field"
                        >
                          <Center>
                            <MdDelete
                              onClick={() => {
                                setItemToDelete(row.original);
                                onOpenConfirmationModal();
                              }}
                              color="red"
                            />
                          </Center>
                        </Tooltip>
                      </Flex>
                    </Td>
                  )}
                  {table === "capturedForm" && (
                    <>
                      <Td cursor="pointer" p={2}>
                        <Flex gap="2" marginLeft="20px">
                          <Tooltip
                            label="Delete form data"
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <MdDelete
                                    onClick={() => {
                                      setItemToDelete(row.original);
                                      onOpenConfirmationModal();
                                    }}
                                    color="red"
                                  />
                                </>
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "apikey" && (
                    <>
                      <Td cursor="pointer" p={2}>
                        <Flex gap="2" marginLeft="20px">
                          <Tooltip
                            label="Delete API key"
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <MdDelete
                                    onClick={() => {
                                      setItemToDelete(row.original);
                                      onOpenConfirmationModal();
                                    }}
                                    color="red"
                                  />
                                </>
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "contact" && (
                    <>
                      <Td p={2}>
                        <Flex gap="2" marginLeft="20px">
                          <Tooltip label="Edit Contact" aria-label="Edit">
                            <Center>
                              <AiOutlineEdit
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  openContactForm();
                                  setSelectedItem(row.original.value);
                                }}
                              />
                            </Center>
                          </Tooltip>
                          <Tooltip
                            label="Delete Contact "
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <MdDelete
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setItemToDelete(row.original);
                                      onOpenConfirmationModal();
                                    }}
                                    color="red"
                                  />
                                </>
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "webhooks" && (
                    <>
                      <Td p={2}>
                        <Flex gap="2" marginLeft="5px">
                          <Tooltip label="Logs" aria-label="Logs">
                            <Center>
                              <LuFileClock
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSelectedItem(row.original);
                                  openWebhookLogs();
                                }}
                              />
                            </Center>
                          </Tooltip>
                          <Tooltip label="Edit Webhook" aria-label="Edit">
                            <Center>
                              <AiOutlineEdit
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSelectedItem(row.original);
                                  openWebhookModal();
                                }}
                              />
                            </Center>
                          </Tooltip>
                          <Tooltip
                            label="Delete Webhook "
                            aria-label="Delete tooltip"
                          >
                            <Center>
                              {loadingRow === row.original.id ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <MdDelete
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setItemToDelete(row.original);
                                      onOpenConfirmationModal();
                                    }}
                                    color="red"
                                  />
                                </>
                              )}
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                  {table === "contactCustomField" && (
                    <>
                      <Td p={2}>
                        <Flex gap="2" marginLeft="20px">
                          <Tooltip label="Edit" aria-label="Edit">
                            <Center>
                              <AiOutlineEdit
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  openContactCustomField();
                                  setSelectedItem(row.original);
                                }}
                              />
                            </Center>
                          </Tooltip>
                          <Tooltip label="Delete" aria-label="Delete tooltip">
                            <Center>
                              <MdDelete
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setItemToDelete(row.original);
                                  onOpenConfirmationModal();
                                }}
                                color="red"
                              />
                            </Center>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
        <DataSourcesModal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          selectedItem={selectedItem}
          fetch={fetch}
        />
        <DataSourcesModalLIST
          isOpen={openList}
          onOpen={onOpenList}
          onClose={onCloseList}
          fetch={fetch}
          selectedItem={selectedItem}
        />
        <DataSourcesList
          isOpen={openSourceList}
          onOpen={onOpenSourceList}
          onClose={onCloseSourceList}
          selectedItem={selectedItem}
        />
        <AssignAssistant
          isOpen={OpenAssistantList}
          onOpen={onOpenAssistantList}
          onClose={onCloseAssistantLIst}
          selectedItem={selectedItem}
          fetch={fetch}
        />
        <WidgetDataSources
          isOpen={OpenWidgetSources}
          onOpen={onOpenWidgetSources}
          onClose={onCloseWidgetSources}
          selectedItem={selectedItem}
        />
        <AssignToolModal
          isOpen={isAssignToolModalOpen}
          onClose={closeAssignToolModal}
          table={table}
          selectedAssistant={selectedItem}
        />
        <Code
          isOpen={isOpenCode}
          onOpen={onOpenCode}
          onClose={onCloseCode}
          selectedItem={selectedItem}
        />
        <AssignIntent
          isOpen={isOpenIntent}
          onOpen={onOpenIntent}
          onClose={onCloseIntent}
          selectedItem={selectedItem}
          fetch={fetch}
        />
        {table === "webhooks" && selectedItem ? (
          <WebhookLogsModal
            isOpen={isWebhookLogsOpen}
            onClose={closeWebhookLogs}
            selectedWebhook={selectedItem}
            table={table}
          />
        ) : null}
        <ConfirmationModal
          table={table}
          isOpen={isConfirmationModalOpen}
          onConfirm={onConfirmDelete}
          onClose={onCloseConfirmationModal}
        />
        {table === "assistant" && selectedItem ? (
          <AssignWebhookModal
            isOpen={isWebhookAssignOpen}
            selectedAssistant={selectedItem}
            onClose={closeWebhookAssign}
          />
        ) : null}
        <FormList
          isOpen={isFormOpen}
          onConfirm={onOpenForm}
          onClose={onFormClose}
          selectedItem={selectedItem}
          fetch={fetch}
        />
        {isContactFormOpen && table === "contact" && selectedItem ? (
          <AddContact
            contactToEdit={selectedItem}
            fetchContact={fetch}
            isOpen={isContactFormOpen}
            onClose={closeContactsForm}
          />
        ) : null}
        {table === "assistant" && selectedItem ? (
          <AddAssistant
            assistantToEdit={selectedItem}
            fetchAssistants={fetch}
            table={"assistant"}
            isOpen={isAssistantEditOpen}
            onClose={closeAssistantEdit}
          />
        ) : null}
        <WebhookModal
          fetchWebhooks={fetch}
          isOpen={isWebhookModalOpen}
          onClose={closeWebhookModal}
          selectedWebhook={selectedItem}
        />
        {table === "contactCustomField" && selectedItem ? (
          <AddEditContactCustomField
            fieldsToEdit={selectedItem}
            reFreshTable={fetch}
            isOpen={isContactCustomFieldOpen}
            onClose={closeContactCustomField}
          />
        ) : null}
        {table === "forms" && selectedItem ? (
          <TriggerToolsModal
            form={selectedItem}
            fetchTools={fetch}
            isOpen={isToolUserFormModalOpen}
            onClose={closeUserFormToolModal}
          />
        ) : null}
      </>
    </Box>
  );
};

export default Table;
